import { useEffect } from 'react';
import WOW from 'wowjs';
import 'animate.css';

const Blog = () => {

    // Initialize Wow.js
    useEffect(() => {
        new WOW.WOW().init();
    }, []);


    return (
        <>
            <div class="hero-wrap" style={{ backgroundImage: 'url(images/visit.jpg)' }}>
                <div class="overlay"></div>
                <div class="container">
                    <div class="row no-gutters slider-text d-flex align-itemd-center justify-content-center">
                        <div class="col-md-9 text-center d-flex align-items-end justify-content-center">
                            <div class="text">
                                <p class="breadcrumbs mb-2"><span class="mr-2"><a href="/">Home</a></span>
                                    <span>Visit</span></p>
                                <h1 class="mb-4 bread">Places to visit</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section class="ftco-section">
                <div class="container">
                    <div class="row d-flex">
                        <div class="col-md-4 d-flex wow slideInLeft">
                            <div class="blog-entry align-self-stretch">
                                <a href="#" class="block-20 rounded"
                                    style={{ backgroundImage: 'url(images/galle_fort_light_house.jpg)' }}>
                                </a>
                                <div class="text mt-3">

                                    <h3 class="heading"><a href="#">Galle Fort</a></h3>
                                    <p>Just 7 mins away from the hotel, the world heritage site, Galle Dutch Fort is
                                        located. Wander up the lighthouse that epitomizes beautiful Dutch architecture
                                        or enjoy a relaxing walk.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 d-flex wow slideInLeft">
                            <div class="blog-entry align-self-stretch">
                                <a href="#" class="block-20 rounded"
                                    style={{ backgroundImage: 'url(images/Diving.jpg)' }}>
                                </a>
                                <div class="text mt-3">

                                    <h3 class="heading"><a href="#">Diving Areas</a></h3>
                                    <p>At Unawatuna, we have around 16 different Dive Sites with different depths from 6
                                        to 30 meters offering a vibrant variety of dives like Reef Dives, Rock Dives,
                                        Wreck Dives and Night Dives.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 d-flex wow slideInLeft">
                            <div class="blog-entry align-self-stretch">
                                <a href="#" class="block-20 rounded"
                                    style={{ backgroundImage: 'url(images/Surfing-Weligama.jpg)' }}>
                                </a>
                                <div class="text mt-3">

                                    <h3 class="heading"><a href="#">
                                        Unawatuna Surfing
                                    </a></h3>
                                    <p>Unawatuna surf is great for beginners and close to the heritage city of Galle.
                                        You can also base yourself here to surf nearby reefs.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 d-flex wow slideInRight">
                            <div className="blog-entry align-self-stretch">
                                <a href="#" class="block-20 rounded"
                                    style={{ backgroundImage: 'url(images/fruit.jpg)' }}>
                                </a>
                                <div className="text mt-3">

                                    <h3 className="heading"><a href="#">Fruit shops in Unawatuna </a></h3>
                                    <p>
                                        The Galle fruit market is located just a few kilometres away from Peacock Hotel,
                                        offering an elaborate choice of tropical fruits.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 d-flex wow slideInRight">
                            <div class="blog-entry align-self-stretch">
                                <a href="#" class="block-20 rounded"
                                    style={{ backgroundImage: 'url(images/turtle.jpg)' }}>
                                </a>
                                <div class="text mt-3">

                                    <h3 class="heading"><a href="#">Turtle Hatchery </a></h3>
                                    <p>Mahamodara Sea Turtle Hatchery is one of such conservation centres and it is
                                        located just a few minutes drive away from Galle, the Hatchery is one of the
                                        many conservation centres that are designated to breed and conserve turtles on
                                        the island and this is too is being run by volunteers. </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 d-flex wow slideInRight">
                            <div className="blog-entry align-self-stretch">
                                <a href="#" class="block-20 rounded"
                                    style={{ backgroundImage: 'url(images/MihiripennaBeach.jpg)' }}>
                                </a>
                                <div className="text mt-3">

                                    <h3 className="heading"><a href="#">Mihiripenna Beach </a></h3>
                                    <p>Mihiripenna Beach is a stunning location; coconut palms, clear water, clean sand,
                                        safe lagoon with fish and lots of very big turtles. </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 d-flex wow slideInLeft">
                            <div className="blog-entry align-self-stretch">
                                <a href="#" className="block-20 rounded"
                                    style={{ backgroundImage: 'url(images/JungleBeach.jpg)' }}>
                                </a>
                                <div className="text mt-3">

                                    <h3 className="heading"><a href="#">
                                        Jungle Beach
                                    </a></h3>
                                    <p>Jungle beach in Unwatuna, Sri Lanka is a popular beach that gets its name because
                                        you have to trek through a jungle to reach. Don't worry its an easy trek.</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 d-flex wow slideInLeft">
                            <div className="blog-entry align-self-stretch">
                                <a href="#" className="block-20 rounded"
                                    style={{ backgroundImage: 'url(images/news-img3.jpg)' }}>
                                </a>
                                <div className="text mt-3">

                                    <h3 className="heading"><a href="#">
                                        Unawatuna Temple
                                    </a></h3>
                                    <p>
                                        Buddhist monastery located very near to world famous Unawatuna with a history of
                                        more than 2000 years. The monastery was built by the king Dewanampiyathissa who
                                        ruled the country more than 2000 years.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="row mt-5">
                        <div class="col text-center">
                            <div class="block-27">
                                <ul >
                                    <li ><a href="#" style={{ color: '#2f89fc', border: '1px solid #2f89fc' }}>&lt;</a></li>
                                    <li class="active" ><span style={{ backgroundColor: '#2f89fc', color: '#fff' }}>1</span></li>
                                    <li><a href="#" style={{ color: '#2f89fc', border: '1px solid #2f89fc' }}>2</a></li>
                                    <li><a href="#" style={{ color: '#2f89fc', border: '1px solid #2f89fc' }}>3</a></li>
                                    <li><a href="#" style={{ color: '#2f89fc', border: '1px solid #2f89fc' }}>4</a></li>
                                    <li><a href="#" style={{ color: '#2f89fc', border: '1px solid #2f89fc' }}>5</a></li>
                                    <li><a href="#" style={{ color: '#2f89fc', border: '1px solid #2f89fc' }}>&gt;</a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Blog;