import React from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import WOW from 'wowjs';
import 'animate.css';

const options = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true
};

const About = () => {

    // Initialize Wow.js
    React.useEffect(() => {
        new WOW.WOW().init();
    }, []);

    return (
        <>
            <div class="hero-wrap" style={{ backgroundImage: 'url(images/banner.jpg)' }}>
                <div class="overlay"></div>
                <div class="container">
                    <div class="row no-gutters slider-text d-flex align-itemd-center justify-content-center">
                        <div class="col-md-9 text-center d-flex align-items-end justify-content-center">
                            <div class="text">
                                <p class="breadcrumbs mb-2"><span class="mr-2"><a href="/">Home</a></span> <span>About Us</span></p>
                                <h1 class="mb-4 bread">About Peacock Hotel</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="ftco-section">
                <div className="container">
                    <div className="row justify-content-center mb-5 pb-3">
                        <div className="col-md-7 heading-section text-center wow fadeInDown" data-wow-delay="0.1s">
                            <span className="subheading" style={{ color: '#2f89fc' }}>Welcome to Peacock Hotel</span>
                            <h2 className="mb-4">UNAWATUNA</h2>
                        </div>
                    </div>
                    <div className="row d-flex">
                        <div className="col-md pr-md-1 d-flex align-self-stretch wow fadeInUp" data-wow-delay="0.2s">
                            <div className="media block-6 services py-4 d-block text-center">
                                <div className="d-flex justify-content-center">
                                    <div className="icon d-flex align-items-center justify-content-center">
                                        <span className="flaticon-reception-bell" style={{ color: '#2f89fc' }}></span>
                                    </div>
                                </div>
                                <div className="media-body">
                                    <h3 className="heading mb-3">Direct Ocean Access</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md px-md-1 d-flex align-self-stretch wow fadeInUp" data-wow-delay="0.4s">
                            <div className="media block-6 services active py-4 d-block text-center">
                                <div className="d-flex justify-content-center">
                                    <div className="icon d-flex align-items-center justify-content-center">
                                        <span className="flaticon-serving-dish" style={{ color: '#2f89fc' }}></span>
                                    </div>
                                </div>
                                <div className="media-body">
                                    <h3 className="heading mb-3">Tasty Food & Drinks</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md px-md-1 d-flex align-self-stretch wow fadeInUp" data-wow-delay="0.6s">
                            <div className="media block-6 services py-4 d-block text-center">
                                <div className="d-flex justify-content-center">
                                    <div className="icon d-flex align-items-center justify-content-center">
                                        <img src="/um.png" style={{ width: '50px' }} alt="Beach Chairs with Umbrellas" />
                                    </div>
                                </div>
                                <div className="media-body">
                                    <h3 className="heading mb-3">Beach Chairs with Umbrellas</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md px-md-1 d-flex align-self-stretch wow fadeInUp" data-wow-delay="0.8s">
                            <div className="media block-6 services py-4 d-block text-center">
                                <div className="d-flex justify-content-center">
                                    <div className="icon d-flex align-items-center justify-content-center">
                                        <span className="flaticon-spa" style={{ color: '#2f89fc' }}></span>
                                    </div>
                                </div>
                                <div className="media-body">
                                    <h3 className="heading mb-3">Affordable Prices</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md pl-md-1 d-flex align-self-stretch wow fadeInUp" data-wow-delay="1s">
                            <div className="media block-6 services py-4 d-block text-center">
                                <div className="d-flex justify-content-center">
                                    <div className="icon d-flex align-items-center justify-content-center">
                                        <span className="ion-ios-bed" style={{ color: '#2f89fc' }}></span>
                                    </div>
                                </div>
                                <div className="media-body">
                                    <h3 className="heading mb-3">Comfortable Rooms</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="ftco-section ftco-wrap-about ftco-no-pt ftco-no-pb">
                <div class="container">
                    <div class="row no-gutters">
                        <div class="col-md-7 order-md-last d-flex wow slideInRight">
                            <div class="img img-1 mr-md-2 " style={{ backgroundImage: 'url(images/about7.jpg)' }}></div>
                            <div class="img img-2 ml-md-2 " style={{ backgroundImage: 'url(images/about8.jpg)' }}></div>
                        </div>
                        <div class="col-md-5 wrap-about pb-md-3  pr-md-5 pb-md-5 pt-md-4 wow slideInLeft">
                            <div class="heading-section mb-4 my-5 my-md-0">
                                <span class="subheading">About Peacock Hotel</span>
                                <h2 class="mb-4">Peacock Hotel Unawatuna, the charming beachfront getaway</h2>
                            </div>
                            <p>Located right by the serene waves of the Indian Ocean, Peacock Hotel Unawatuna is the ultimate beachfront retreat.
                                Just a stone’s throw from Unawatuna Railway Station and a short drive from the historic Galle Fort, this charming
                                hotel blends convenience with coastal side experience. Being the perfect place to live close to the sea, Peacock Hotel Unawatuna, a charming beachfront getaway, Located just 1 km from Unawatuna Railway Station and 6.6 km from the historic Galle Fort, this affordable hotel is 168 km from Bandaranaike International Airport. offers free Wi-Fi, an in-house restaurant, and complimentary parking.
                                Rooms boast stunning Indian Ocean views, featuring a cozy sofa, private balcony, and en suite bathroom with free toiletries.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section class="testimony-section">
                <div class="container">
                    <div class="row no-gutters justify-content-center mt-3 mb-3 wow slideInUp">
                        Being the perfect place to live close to the sea, Peacock Hotel Unawatuna, a charming beachfront getaway, Located just 1 km from Unawatuna Railway Station and 6.6 km from the historic Galle Fort, this affordable hotel is 168 km from Bandaranaike International Airport. offers free Wi-Fi, an in-house restaurant, and complimentary parking.
                        Rooms boast stunning Indian Ocean views, featuring a cozy sofa, private balcony, and en suite bathroom with free toiletries.
                        The 24-hour front desk offers BBQ facilities, laundry services, travel arrangements, and car rentals.
                        Enjoy authentic Sri Lankan and Chinese dishes at Peacock Hotel, with room service available for a more private dining experience.

                        The rooms at Peacock Hotel offer breathtaking ocean views, with private balconies that invite you to soak in the scenery. Each room features a cozy sofa and an en suite bathroom complete with complimentary toiletries, ensuring a comfortable stay.

                        Guests can indulge in a culinary journey at the in-house restaurant, serving authentic Sri Lankan and Chinese dishes. For a more intimate dining experience, room service is available, allowing you to enjoy your meal with the stunning ocean backdrop.

                        The 24-hour front desk is always ready to assist with a range of services including BBQ facilities, laundry, travel arrangements, and car rentals. Whether planning a local excursion or simply enjoying a relaxed day by the beach, the hotel staff ensures a seamless experience.

                        Complimentary parking and the hotel’s prime location make it an ideal base for exploring the area. With its combination of affordability, comfort, and unparalleled seaside proximity, Peacock Hotel Unawatuna is a perfect haven for beach lovers and adventurers alike.

                    </div>
                </div>
            </section>

            <section class="instagram">
                <div class="container-fluid">
                    <div class="row no-gutters justify-content-center pb-5 wow fadeInDown" data-wow-delay="0.3s">
                        <div class="col-md-7 text-center heading-section ">
                            <span class="subheading">Our Gallery </span>
                            <h2><span>
                                Take a look into the brilliant sceneries captured at our hotel alongside with the sceneries of the sea!</span></h2>
                        </div>
                    </div>
                    <div class="row no-gutters">
                        <div class="col-sm-12 col-md ">
                            <a href="images/insta1.jpg" target="_blank" class="insta-img image-popup" style={{ backgroundImage: "url(images/insta1.jpg)" }}>
                                <div class="icon d-flex justify-content-center">
                                    <span class="icon-instagram align-self-center"></span>
                                </div>
                            </a>
                        </div>
                        <div class="col-sm-12 col-md ">
                            <a href="images/insta2.jpg" target="_blank" class="insta-img image-popup" style={{ backgroundImage: "url(images/insta2.jpg)" }}>
                                <div class="icon d-flex justify-content-center">
                                    <span class="icon-instagram align-self-center"></span>
                                </div>
                            </a>
                        </div>
                        <div class="col-sm-12 col-md ">
                            <a href="images/insta3.jpg" target="_blank" class="insta-img image-popup" style={{ backgroundImage: "url(images/insta3.jpg)" }}>
                                <div class="icon d-flex justify-content-center">
                                    <span class="icon-instagram align-self-center"></span>
                                </div>
                            </a>
                        </div>
                        <div class="col-sm-12 col-md ">
                            <a href="images/insta4.jpg" target="_blank" class="insta-img image-popup" style={{ backgroundImage: "url(images/insta4.jpg)" }}>
                                <div class="icon d-flex justify-content-center">
                                    <span class="icon-instagram align-self-center"></span>
                                </div>
                            </a>
                        </div>
                        <div class="col-sm-12 col-md ">
                            <a href="images/insta5.jpg" target="_blank" class="insta-img image-popup" style={{ backgroundImage: "url(images/insta5.jpg)" }}>
                                <div class="icon d-flex justify-content-center">
                                    <span class="icon-instagram align-self-center"></span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About;