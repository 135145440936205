import React, { useState } from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import { FaLongArrowAltRight } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';
import 'animate.css';
import WOW from 'wowjs';

const options = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true,
    animateOut: 'fadeOut',
    animateIn: 'fadeIn',
};

export const Home = () => {


    const [checkIn, setCheckIn] = useState('');
    const [checkOut, setCheckOut] = useState('');
    const [adults, setAdults] = useState(1);
    const [room, setRoom] = useState('');

    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        // Process the booking with the captured values
        const bookingDetails = {
            checkIn,
            checkOut,
            adults,
            room,
        };

        console.log('Booking details:', bookingDetails);

        // Navigate to the next page with the booking details
        navigate('/contact', { state: bookingDetails });
    };


    // Initialize Wow.js
    React.useEffect(() => {
        new WOW.WOW().init();
    }, []);

    return (
        <>
            <div className="hero">
                <section className="home-slider">
                    <OwlCarousel className="owl-carousel" {...options}>
                        <div className="slider-item" style={{ backgroundImage: 'url(images/landing2.jpg)' }}>
                            <div className="overlay"></div>
                            <div className="container" style={{ height: '100%' }}>
                                <div className="custom-row">
                                    <div className="custom-col">
                                        <div className="text1"  >
                                            {/* <h4>More than a hotel... an experience</h4> */}
                                            <h1 className="mb-3" >Welcome to <br />Peacock Hotel!</h1>
                                            <p>Grab your  <span style={{ color: 'white' }}> room </span>in today </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slider-item" style={{ backgroundImage: 'url(images/insta3.jpg)' }}>
                            <div className="overlay"></div>
                            <div className="container" style={{ height: '100%' }}>
                                <div className="custom-row">
                                    <div className="custom-col">
                                        <div className="text1" >
                                            {/* <h4>Peacock Hotel & Resort</h4> */}
                                            <h1 className="mb-3" >Here life's colors become the sea's!</h1>
                                            <p>Beyond a  <span style={{ color: 'white' }}>hotel</span>, an  experience</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                </section>
            </div>

            <section className="ftco-booking ftco-section ftco-no-pt ftco-no-pb">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-lg-12">
                            <form className="booking-form aside-stretch" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="col-md d-flex py-md-4">
                                        <div className="form-group align-self-stretch d-flex align-items-end wow fadeInUp" data-wow-delay="0.2s">
                                            <div className="wrap align-self-stretch py-3 px-4">
                                                <label style={{ color: '#2f89fc' }} htmlFor="date1">Check-in Date</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    id="date1"
                                                    value={checkIn}
                                                    onChange={(e) => setCheckIn(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md d-flex py-md-4">
                                        <div className="form-group align-self-stretch d-flex align-items-end wow fadeInUp" data-wow-delay="0.4s">
                                            <div className="wrap align-self-stretch py-3 px-4">
                                                <label style={{ color: '#2f89fc' }} htmlFor="date2">Check-out Date</label>
                                                <input
                                                    type="date"
                                                    className="form-control"
                                                    id="date2"
                                                    value={checkOut}
                                                    onChange={(e) => setCheckOut(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md d-flex py-md-4">
                                        <div className="form-group align-self-stretch d-flex align-items-end wow fadeInUp" data-wow-delay="0.6s">
                                            <div className="wrap align-self-stretch py-3 px-4">
                                                <label style={{ color: '#2f89fc' }} htmlFor="room">Room</label>
                                                <div className="form-field">
                                                    <div className="select-wrap">
                                                        <div className="icon"><span className="ion-ios-arrow-down"></span></div>
                                                        <select
                                                            className="form-control"
                                                            id="room"
                                                            name="room"
                                                            value={room}
                                                            onChange={(e) => setRoom(e.target.value)}
                                                        >
                                                            <option value="">Select a room</option>
                                                            <option value="One-bedroom apartment">One-bedroom apartment</option>
                                                            <option value="Triple room with sea view">Triple room with sea view</option>
                                                            <option value="Family room with sea view">Family room with sea view</option>
                                                            <option value="Double room with sea view">Double room with sea view</option>
                                                            <option value="Deluxe double room with side sea view">Deluxe double room with side sea view</option>
                                                            <option value="Triple room with sea side view">Triple room with sea side view</option>
                                                            <option value="Single room with sea view">Single room with sea view</option>
                                                            <option value="Standard triple room with sea view">Standard triple room with sea view</option>
                                                            <option value="Apartment with Sea View">Apartment with Sea View</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md d-flex py-md-4">
                                        <div className="form-group align-self-stretch d-flex align-items-end wow fadeInUp" data-wow-delay="0.8s">
                                            <div className="wrap align-self-stretch py-3 px-4">
                                                <label style={{ color: '#2f89fc' }} htmlFor="adult">Guests</label>
                                                <input
                                                    type="number"
                                                    className="form-control"
                                                    id="adult"
                                                    value={adults}
                                                    min="0"
                                                    max="10"
                                                    onChange={(e) => setAdults(parseInt(e.target.value))}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md d-flex">
                                        <div className="form-group d-flex align-self-stretch wow fadeInUp" data-wow-delay="1s">
                                            <button type='submit' className="btn btn-primary py-5 py-md-3 px-4 align-self-stretch d-block">
                                                <span>Book Now<small>Best Price Guaranteed!</small></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ftco-section">
                <div className="container">
                    <div className="row justify-content-center mb-5 pb-3">
                        <div className="col-md-7 heading-section text-center wow fadeInDown" data-wow-delay="0.1s">
                            <span className="subheading" style={{ color: '#2f89fc' }}>Welcome to Peacock Hotel</span>
                            <h2 className="mb-4">UNAWATUNA</h2>
                        </div>
                    </div>
                    <div className="row d-flex">
                        <div className="col-md pr-md-1 d-flex align-self-stretch wow fadeInUp" data-wow-delay="0.2s">
                            <div className="media block-6 services py-4 d-block text-center">
                                <div className="d-flex justify-content-center">
                                    <div className="icon d-flex align-items-center justify-content-center">
                                        <span className="flaticon-reception-bell" style={{ color: '#2f89fc' }}></span>
                                    </div>
                                </div>
                                <div className="media-body">
                                    <h3 className="heading mb-3">Direct Ocean Access</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md px-md-1 d-flex align-self-stretch wow fadeInUp" data-wow-delay="0.4s">
                            <div className="media block-6 services active py-4 d-block text-center">
                                <div className="d-flex justify-content-center">
                                    <div className="icon d-flex align-items-center justify-content-center">
                                        <span className="flaticon-serving-dish" style={{ color: '#2f89fc' }}></span>
                                    </div>
                                </div>
                                <div className="media-body">
                                    <h3 className="heading mb-3">Tasty Food & Drinks</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md px-md-1 d-flex align-self-stretch wow fadeInUp" data-wow-delay="0.6s">
                            <div className="media block-6 services py-4 d-block text-center">
                                <div className="d-flex justify-content-center">
                                    <div className="icon d-flex align-items-center justify-content-center">
                                        <img src="/um.png" style={{ width: '50px' }} alt="Beach Chairs with Umbrellas" />
                                    </div>
                                </div>
                                <div className="media-body">
                                    <h3 className="heading mb-3">Beach Chairs with Umbrellas</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md px-md-1 d-flex align-self-stretch wow fadeInUp" data-wow-delay="0.8s">
                            <div className="media block-6 services py-4 d-block text-center">
                                <div className="d-flex justify-content-center">
                                    <div className="icon d-flex align-items-center justify-content-center">
                                        <span className="flaticon-spa" style={{ color: '#2f89fc' }}></span>
                                    </div>
                                </div>
                                <div className="media-body">
                                    <h3 className="heading mb-3">Affordable Prices</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-md pl-md-1 d-flex align-self-stretch wow fadeInUp" data-wow-delay="1s">
                            <div className="media block-6 services py-4 d-block text-center">
                                <div className="d-flex justify-content-center">
                                    <div className="icon d-flex align-items-center justify-content-center">
                                        <span className="ion-ios-bed" style={{ color: '#2f89fc' }}></span>
                                    </div>
                                </div>
                                <div className="media-body">
                                    <h3 className="heading mb-3">Comfortable Rooms</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="ftco-section ftco-wrap-about ftco-no-pt ftco-no-pb">
                <div className="container">
                    <div className="row no-gutters">
                        <div className="col-md-7 order-md-last d-flex">
                            {/* Parallax effect on scroll */}
                            <div
                                className="img img-1 mr-md-2 wow fadeIn"
                                style={{ backgroundImage: 'url(images/about7.jpg)' }}
                                data-wow-delay="0.3s"
                            ></div>
                            <div
                                className="img img-2 ml-md-2 wow fadeIn"
                                style={{ backgroundImage: 'url(images/about8.jpg)' }}
                                data-wow-delay="0.6s"
                            ></div>
                        </div>
                        <div className="col-md-5 wrap-about pb-md-3 pr-md-5 pb-md-5 pt-md-4">
                            <div className="heading-section mb-4 my-5 my-md-0 wow fadeInUp">
                                <span className="subheading" style={{ color: '#2f89fc' }}>About Peacock Hotel</span>
                                <h2 className="mb-4">Peacock Hotel Unawatuna, the charming beachfront getaway</h2>
                            </div>
                            <p className="wow fadeInUp" data-wow-delay="0.4s">
                                Located right by the serene waves of the Indian Ocean, Peacock Hotel Unawatuna is the ultimate beachfront retreat.
                                Just a stone’s throw from Unawatuna Railway Station and a short drive from the historic Galle Fort, this charming
                                hotel blends convenience with coastal side experience.
                            </p>
                            {/* Animated button */}
                            <p className="wow fadeInUp" data-wow-delay="0.6s">
                                <a href="/room" className="btn btn-secondary rounded animated-btn">Reserve Your Room Now</a>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="testimony-section">
                <div className="container">
                    <div className="row no-gutters justify-content-center">
                        <div className="col-md-5 d-flex wow fadeInLeft" data-wow-delay="0.2s">
                            <div
                                className="testimony-img aside-stretch-2"
                                style={{ backgroundImage: 'url(images/testimony2.jpg)' }}
                            ></div>
                        </div>
                        <div className="col-md-7 py-5 pl-md-5 wow fadeInRight" data-wow-delay="0.4s">
                            <div className="py-md-5">
                                <div className="heading-section mb-4 wow fadeInUp" data-wow-delay="0.6s">
                                    <span className="subheading" style={{ color: '#2f89fc' }}>Testimony</span>
                                    <h2 className="mb-0">Happy Customer</h2>
                                </div>
                                <OwlCarousel className="carousel-testimony owl-carousel wow fadeIn" {...options} data-wow-delay="0.8s">
                                    <div className="item">
                                        <div className="testimony-wrap pb-4 wow fadeInUp" data-wow-delay="0.8s">
                                            <div className="text">
                                                <p className="mb-4">
                                                    A small river named Duden flows by their place and supplies it with the necessary regelialia. It
                                                    is a paradisematic country, in which roasted parts of sentences fly into your mouth.
                                                </p>
                                            </div>
                                            <div className="d-flex">
                                                <div
                                                    className="user-img wow fadeIn"
                                                    data-wow-delay="1s"
                                                    style={{ backgroundImage: 'url(images/person_1.jpg)' }}
                                                ></div>
                                                <div className="pos ml-3">
                                                    <p className="name">Gerald Hodson</p>
                                                    <span className="position">Businessman</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="testimony-wrap pb-4 wow fadeInUp" data-wow-delay="1.2s">
                                            <div className="text">
                                                <p className="mb-4">
                                                    A small river named Duden flows by their place and supplies it with the necessary regelialia. It
                                                    is a paradisematic country, in which roasted parts of sentences fly into your mouth.
                                                </p>
                                            </div>
                                            <div className="d-flex">
                                                <div
                                                    className="user-img wow fadeIn"
                                                    data-wow-delay="1.4s"
                                                    style={{ backgroundImage: 'url(images/person_2.jpg)' }}
                                                ></div>
                                                <div className="pos ml-3">
                                                    <p className="name">Gerald Hodson</p>
                                                    <span className="position">Businessman</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="testimony-wrap pb-4 wow fadeInUp" data-wow-delay="1.6s">
                                            <div className="text">
                                                <p className="mb-4">
                                                    A small river named Duden flows by their place and supplies it with the necessary regelialia. It
                                                    is a paradisematic country, in which roasted parts of sentences fly into your mouth.
                                                </p>
                                            </div>
                                            <div className="d-flex">
                                                <div
                                                    className="user-img wow fadeIn"
                                                    data-wow-delay="1.8s"
                                                    style={{ backgroundImage: 'url(images/person_3.jpg)' }}
                                                ></div>
                                                <div className="pos ml-3">
                                                    <p className="name">Gerald Hodson</p>
                                                    <span className="position">Businessman</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item">
                                        <div className="testimony-wrap pb-4 wow fadeInUp" data-wow-delay="2s">
                                            <div className="text">
                                                <p className="mb-4">
                                                    A small river named Duden flows by their place and supplies it with the necessary regelialia. It
                                                    is a paradisematic country, in which roasted parts of sentences fly into your mouth.
                                                </p>
                                            </div>
                                            <div className="d-flex">
                                                <div
                                                    className="user-img wow fadeIn"
                                                    data-wow-delay="2.2s"
                                                    style={{ backgroundImage: 'url(images/person_4.jpg)' }}
                                                ></div>
                                                <div className="pos ml-3">
                                                    <p className="name">Gerald Hodson</p>
                                                    <span className="position">Businessman</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </OwlCarousel>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

{/*             <section class="ftco-section ftco-no-pb ftco-room">
                <div class="container-fluid px-0">
                    <div class="row no-gutters justify-content-center mb-5 pb-3 wow fadeInUp" data-wow-duration="1s">
                        <div class="col-md-7 heading-section text-center">
                            <span class="subheading">Peacock Rooms</span>
                            <h2 class="mb-3">Our Rooms</h2>
                            <p style={{ paddingRight: '15px', paddingLeft: '15px' }}>Our rooms vary between different types where you can choose flexibly according to the number of pax and your purpose!</p>
                        </div>
                    </div>
                    <div class="row no-gutters"> */}
                        {/* <!-- First row sliding up --> */}
{/*                         <div class="col-lg-6 wow slideInUp" data-wow-delay="0.1s">
                            <div class="room-wrap d-md-flex">
                                <a href="#" class="img" style={{ backgroundImage: "url(images/room-6.jpg)" }}></a>
                                <div class="half left-arrow d-flex align-items-center">
                                    <div class="text p-4 text-center">
                                        <p class="star mb-0"><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span></p>
                                        <p class="mb-0"><span class="price mr-1">$120.00</span> <span class="per">per night</span></p>
                                        <h3 class="mb-3"><a href="#">One-bedroom apartment</a></h3>
                                        <p class="mb-0"><span class="per">This apartment is comprised of 1 living room, 1 separate bedroom and 1 bathroom with a shower and free
                                            toiletries. This apartment is air-conditioned and features a seating area, a dining area and a wardrobe. The unit offers 2 beds.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 wow slideInUp" data-wow-delay="0.2s">
                            <div class="room-wrap d-md-flex">
                                <a href="#" class="img" style={{ backgroundImage: "url(images/room-1.jpg)" }}></a>
                                <div class="half left-arrow d-flex align-items-center">
                                    <div class="text p-4 text-center">
                                        <p class="star mb-0"><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span></p>
                                        <p class="mb-0"><span class="price mr-1">$120.00</span> <span class="per">per night</span></p>
                                        <h3 class="mb-3"><a href="#">Triple room with sea view</a></h3>
                                        <p class="mb-0"><span class="per">The triple room offers air conditioning, a seating area, a terrace with sea views
                                            as well as a private bathroom featuring a shower. The unit offers 2 beds.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
 */}
                        {/* <!-- Second row sliding in from the left and right --> */}
{/*                         <div class="col-lg-6 wow slideInLeft" data-wow-delay="0.3s">
                            <div class="room-wrap d-md-flex">
                                <a href="#" class="img order-md-last" style={{ backgroundImage: "url(images/room-2.jpg)" }}></a>
                                <div class="half right-arrow d-flex align-items-center">
                                    <div class="text p-4 text-center">
                                        <p class="star mb-0"><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span></p>
                                        <p class="mb-0"><span class="price mr-1">$120.00</span> <span class="per">per night</span></p>
                                        <h3 class="mb-3"><a href="#">Family room with sea view</a></h3>
                                        <p class="mb-0"><span class="per">The family room provides air conditioning, a seating area, a terrace with
                                            sea views as well as a private bathroom featuring a shower. The unit offers 3 beds.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 wow slideInRight" data-wow-delay="0.4s">
                            <div class="room-wrap d-md-flex">
                                <a href="#" class="img order-md-last" style={{ backgroundImage: "url(images/room-3.jpg)" }}></a>
                                <div class="half right-arrow d-flex align-items-center">
                                    <div class="text p-4 text-center">
                                        <p class="star mb-0"><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span></p>
                                        <p class="mb-0"><span class="price mr-1">$120.00</span> <span class="per">per night</span></p>
                                        <h3 class="mb-3"><a href="#">Double room with sea view</a></h3>
                                        <p class="mb-0"><span class="per">Rooms are cooled with either a fan or air conditioning. They include
                                            a private balcony and en suite bathroom.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* <!-- Continue with alternating slide in effects --> */}
{/*                         <div class="col-lg-6 wow slideInUp" data-wow-delay="0.5s">
                            <div class="room-wrap d-md-flex">
                                <a href="#" class="img" style={{ backgroundImage: "url(images/room-4.jpg)" }}></a>
                                <div class="half left-arrow d-flex align-items-center">
                                    <div class="text p-4 text-center">
                                        <p class="star mb-0"><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span></p>
                                        <p class="mb-0"><span class="price mr-1">$120.00</span> <span class="per">per night</span></p>
                                        <h3 class="mb-3"><a href="#">Deluxe double room with side sea view</a></h3>
                                        <p class="mb-0"><span class="per">The double room features air conditioning, a seating area, a terrace with sea views as well
                                            as a private bathroom boasting a shower. The unit has 1 bed.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 wow slideInUp" data-wow-delay="0.6s">
                            <div class="room-wrap d-md-flex">
                                <a href="#" class="img" style={{ backgroundImage: "url(images/room-5.jpg)" }}></a>
                                <div class="half left-arrow d-flex align-items-center">
                                    <div class="text p-4 text-center">
                                        <p class="star mb-0"><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span></p>
                                        <p class="mb-0"><span class="price mr-1">$120.00</span> <span class="per">per night</span></p>
                                        <h3 class="mb-3"><a href="#">Triple room with sea side view</a></h3>
                                        <p class="mb-0"><span class="per">The triple room offers air conditioning, a seating area, a terrace with sea
                                            views as well as a private bathroom boasting a shower. The unit has 2 beds.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* <!-- Additional rows with side slide animations --> */}
{/*                         <div class="col-lg-6 wow slideInLeft" data-wow-delay="0.7s">
                            <div class="room-wrap d-md-flex">
                                <a href="#" class="img order-md-last" style={{ backgroundImage: "url(images/room-6.jpg)" }}></a>
                                <div class="half left-arrow d-flex align-items-center">
                                    <div class="text p-4 text-center">
                                        <p class="star mb-0"><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span></p>
                                        <p class="mb-0"><span class="price mr-1">$120.00</span> <span class="per">per night</span></p>
                                        <h3 class="mb-3"><a href="#">Single room with sea view</a></h3>
                                        <p class="mb-0"><span class="per">The single room provides air conditioning, a seating area, a terrace with sea
                                            views as well as a private bathroom featuring a shower.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 wow slideInRight" data-wow-delay="0.8s">
                            <div class="room-wrap d-md-flex">
                                <a href="#" class="img order-md-last" style={{ backgroundImage: "url(images/room-3.jpg)" }}></a>
                                <div class="half left-arrow d-flex align-items-center">
                                    <div class="text p-4 text-center">
                                        <p class="star mb-0"><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span></p>
                                        <p class="mb-0"><span class="price mr-1">$120.00</span> <span class="per">per night</span></p>
                                        <h3 class="mb-3"><a href="#">Standard triple room with sea view</a></h3>
                                        <p class="mb-0"><span class="per">The triple room offers air conditioning, a seating area,
                                            a terrace with sea views as well as a private bathroom featuring a shower. The unit offers 2 beds.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div> */}

                        {/* <!-- last row sliding up --> */}
{/*                         <div class="col-lg-6 mx-auto wow fadeInUp" data-wow-delay="0.9s">
                            <div class="room-wrap d-md-flex">
                                <a href="#" class="img" style={{ backgroundImage: "url(images/room-1.jpg)" }}></a>
                                <div class="half left-arrow d-flex align-items-center">
                                    <div class="text p-4 text-center">
                                        <p class="star mb-0"><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span></p>
                                        <p class="mb-0"><span class="price mr-1">$120.00</span> <span class="per">per night</span></p>
                                        <h3 class="mb-3"><a href="#">Apartment with Sea View</a></h3>
                                        <p class="mb-0"><span class="per">The apartment offers air conditioning, a seating area, a terrace with
                                            sea views as well as a private bathroom featuring a shower. The unit has 3 beds.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}


            <section class="ftco-section">
                <div class="container">
                    <div class="row justify-content-center mb-5 pb-3">
                        <div class="col-md-7 heading-section text-center wow fadeInUp" data-wow-duration="1s">
                            <span class="subheading">visit</span>
                            <h2>Places To Visit</h2>
                        </div>
                    </div>
                    <div class="row d-flex">
                        <div class="col-md-4 col-lg-4 d-flex flex-column wow fadeInUp" data-wow-delay="0.1s">
                            <img src='images/galle_fort_light_house.jpg' height='255px' width='100%' />
                            <div className='d-flex w-100  justify-content-between px-2' style={{ backgroundColor: '#efefef' }} >
                                <h5 className='font-weight-bold'>Galle fort</h5>
                                <div className='d-flex align-items-center justify-content-end '
                                    style={{ gap: '10px' }}>
                                    <a href='/blog' style={{ fontSize: '13px', color: '#b8a680' }}>Read More</a>
                                    <FaLongArrowAltRight />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-4 d-flex flex-column wow fadeInUp" data-wow-delay="0.2s">
                            <img src='images/place2.jpg' height='255px' width='100%' />
                            <div className='d-flex w-100  justify-content-between px-2' style={{ backgroundColor: '#efefef' }} >
                                <h5 className='font-weight-bold'>Unawatuna Surfing </h5>
                                <div className='d-flex align-items-center justify-content-end '
                                    style={{ gap: '10px' }}>
                                    <a href='/blog' style={{ fontSize: '13px', color: '#b8a680' }}>Read More</a>
                                    <FaLongArrowAltRight />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-4 d-flex flex-column wow fadeInUp" data-wow-delay="0.3s">
                            <img src='images/news-img3.jpg' height='255px' width='100%' />
                            <div className='d-flex w-100  justify-content-between px-2' style={{ backgroundColor: '#efefef' }} >
                                <h5 className='font-weight-bold'>Jungle Beach </h5>
                                <div className='d-flex align-items-center justify-content-end '
                                    style={{ gap: '10px' }}>
                                    <a href='/blog' style={{ fontSize: '13px', color: '#b8a680' }}>Read More</a>
                                    <FaLongArrowAltRight />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="instagram">
                <div class="container-fluid">
                    <div class="row no-gutters justify-content-center pb-5 wow fadeInDown" data-wow-delay="0.3s">
                        <div class="col-md-7 text-center heading-section ">
                            <span class="subheading">Our Gallery </span>
                            <h2><span>
                                Take a look into the brilliant sceneries captured at our hotel alongside with the sceneries of the sea!</span></h2>
                        </div>
                    </div>
                    <div class="row no-gutters">
                        <div class="col-sm-12 col-md ">
                            <a href="images/insta1.jpg" target="_blank" class="insta-img image-popup" style={{ backgroundImage: "url(images/insta1.jpg)" }}>
                                <div class="icon d-flex justify-content-center">
                                    <span class="icon-instagram align-self-center"></span>
                                </div>
                            </a>
                        </div>
                        <div class="col-sm-12 col-md ">
                            <a href="images/insta2.jpg" target="_blank" class="insta-img image-popup" style={{ backgroundImage: "url(images/insta2.jpg)" }}>
                                <div class="icon d-flex justify-content-center">
                                    <span class="icon-instagram align-self-center"></span>
                                </div>
                            </a>
                        </div>
                        <div class="col-sm-12 col-md ">
                            <a href="images/insta3.jpg" target="_blank" class="insta-img image-popup" style={{ backgroundImage: "url(images/insta3.jpg)" }}>
                                <div class="icon d-flex justify-content-center">
                                    <span class="icon-instagram align-self-center"></span>
                                </div>
                            </a>
                        </div>
                        <div class="col-sm-12 col-md ">
                            <a href="images/insta4.jpg" target="_blank" class="insta-img image-popup" style={{ backgroundImage: "url(images/insta4.jpg)" }}>
                                <div class="icon d-flex justify-content-center">
                                    <span class="icon-instagram align-self-center"></span>
                                </div>
                            </a>
                        </div>
                        <div class="col-sm-12 col-md ">
                            <a href="images/insta5.jpg" target="_blank" class="insta-img image-popup" style={{ backgroundImage: "url(images/insta5.jpg)" }}>
                                <div class="icon d-flex justify-content-center">
                                    <span class="icon-instagram align-self-center"></span>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
