const Navbar = () => {
    return(
        <>
         {/* nav */}
         <nav class="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light" id="ftco-navbar" style={{ background: 'rgba(218, 177, 149, 0.329)'}}>
                <div class="container">
                        <a className="navbar-brand" href="/"><img src='images/p.png' style={{width: '72px', height: '79px',padding:'5px'}}/></a>


                    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#ftco-nav" aria-controls="ftco-nav" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="oi oi-menu"></span> Menu
                    </button>

                    <div class="collapse navbar-collapse" id="ftco-nav">
                        <ul class="navbar-nav ml-auto">
                            <li class="nav-item"><a href="/" class="nav-link" >Home</a></li>
                            <li class="nav-item"><a href="/room" class="nav-link">Our Rooms</a></li>
                            <li class="nav-item"><a href="/restaurant" class="nav-link">Restaurant</a></li>
                            <li class="nav-item"><a href="/about" class="nav-link">About Us</a></li>
                            <li class="nav-item"><a href="/blog" class="nav-link">More Places</a></li>
                            <li class="nav-item"><a href="/contact" class="nav-link">Contact</a></li>
                        </ul>
                    </div>
                </div>
            </nav>
            {/* <!-- END nav --> */}
        </>
    )
}

export default Navbar;