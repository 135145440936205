const Footer = () => {
    return (
        <>
            <footer class="ftco-footer ftco-section img" style={{ backgroundImage: "url(images/footer.jpg)" }}>
                <div class="overlay"></div>
                <div class="container">
                    <div class="row ">
                        <div class="col-md">
                            <div class="ftco-footer-widget mb-4">
                                <h2 class="ftco-heading-2">Peacock Hotel</h2>
                                <p>Book our hotel to have the finest seaside experience and best cuisine in the
                                    country! </p>
                                <ul class="ftco-footer-social list-unstyled float-md-left float-lft mt-5">
                                    <li class=""><a href="#"><span class="icon-twitter"></span></a></li>
                                    <li class=""><a href="https://www.facebook.com/PeacockUnawatuna/" target="_blank"><span class="icon-facebook"></span></a></li>
                                    <li class=""><a href="#"><span class="icon-instagram"></span></a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="ftco-footer-widget mb-4 ml-md-5">
                                <h2 class="ftco-heading-2">Useful Links</h2>
                                <ul class="list-unstyled">
                                    <li><a href="/blog" class="py-2 d-block">Blog</a></li>
                                    <li><a href="/room" class="py-2 d-block">Rooms</a></li>
                                    <li><a href="#" class="py-2 d-block">Amenities</a></li>
                                    <li><a href="#" class="py-2 d-block">Gift Card</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="ftco-footer-widget mb-4">
                                <h2 class="ftco-heading-2">Privacy</h2>
                                <ul class="list-unstyled">
                                    <li><a href="#" class="py-2 d-block">Career</a></li>
                                    <li><a href="/about" class="py-2 d-block">About Us</a></li>
                                    <li><a href="/contact" class="py-2 d-block">Contact Us</a></li>
                                    <li><a href="#" class="py-2 d-block">Services</a></li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-md">
                            <div class="ftco-footer-widget mb-4">
                                <h2 class="ftco-heading-2">Have a Questions?</h2>
                                <div class="block-23 mb-3">
                                    <ul>
                                        <li>
                                            <span class="icon icon-map-marker"></span>
                                            <span class="text">Peacock hotel,
                                                Matara Road,
                                                Peellagoda, Unawatuna</span>
                                        </li>
                                        <li>
                                            <span class="icon icon-phone"></span>
                                            <span class="text">(+94) 77 648 0155
                                            </span>
                                        </li>
                                        <li>
                                            <span class="icon icon-envelope"></span>
                                            <span
                                                class="text">peacockhotelunawatuna1@gmail.com
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 text-center">

                            <p>
                                {/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}
                                Copyright {new Date().getFullYear()}.
                                All rights reserved. Peacock Hotel, designed by <a
                                    href="https://www.loopyholdings.com/" target="_blank">Loopy Holdings</a>
                                {/* <!-- Link back to Colorlib can't be removed. Template is licensed under CC BY 3.0. --> */}
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer;