import React, {useState} from 'react';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import OwlCarousel from 'react-owl-carousel';
import 'animate.css';
import WOW from 'wowjs';

const options = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1,
    autoplay: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: true
};


const Restaurant = () => {
    const [isVisible, setIsVisible] = useState(false);

    const toggleVisibility = (event) => {
        event.preventDefault();
        setIsVisible(!isVisible);
    };

      // Initialize Wow.js
      React.useEffect(() => {
        new WOW.WOW().init();
    }, []);

    return (
        <>
            <div class="hero-wrap" style={{ backgroundImage: 'url(images/banner.jpg)' }}>
                <div class="overlay"></div>
                <div class="container">
                    <div class="row no-gutters slider-text d-flex align-itemd-center justify-content-center">
                        <div class="col-md-9 text-center d-flex align-items-end justify-content-center">
                            <div class="text">
                                <p class="breadcrumbs mb-2"><span class="mr-2"><a href="/">Home</a></span> <span>Restaurant</span></p>
                                <h1 class="mb-4 bread">Restaurant</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section class="ftco-section">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 wow slideInLeft">
                            <div class="single-slider-resto mb-4 mb-md-0">
                                <OwlCarousel className="owl-carousel" {...options}>
                                    <div class="item">
                                        <div class="resto-img rounded" style={{ backgroundImage: 'url(images/res2.jpg)' }}></div>
                                    </div>
                                    <div class="item">
                                        <div class="resto-img rounded" style={{ backgroundImage: 'url(images/SNY09.jpg)' }}></div>
                                    </div>
                                    <div class="item">
                                        <div class="resto-img rounded" style={{ backgroundImage: 'url(images/res5.jpg)' }}></div>
                                    </div>
                                    </OwlCarousel>
                            </div>
                        </div>
                        <div class="col-md-6 pl-md-5 wow slideInRight">
                            <div class="heading-section mb-4 my-5 my-md-0">
                                <span class="subheading" style={{color: '#2f89fc'}}>Food and</span>
                                <h2 class="mb-4 "> RESTAURANT</h2>
                            </div>
                            <p>Authentic Sri Lankan and Chinese cuisines can be enjoyed at Peacock Restaurant, where a culinary journey awaits. Delight in traditional Sri Lankan dishes such as spicy fish curry, fragrant coconut sambol, and hoppers. Each dish is crafted to perfection, ensuring a memorable dining experience. .</p>
                            <p>Complement your meal with a variety of beverages, from refreshing tropical fruit juices and Sri Lankan teas to fine wines and classic cocktails. Whether dining at the restaurant or in your room, the array of food and drink options at Peacock Restaurant guarantees a satisfying and diverse culinary adventure.</p>
                            {isVisible && (
                                <div>
                                    <p>For those who prefer dining in the comfort of their rooms, our room service offers a wide selection of meals from both cuisines. Enjoy the same high-quality food without stepping out, with a menu that caters to every palate and preference.</p>
                                </div>
                            )}
                            <p><a href="#" onClick={toggleVisibility} class="btn btn-secondary rounded">{isVisible ? 'Hide info' : 'More info'}</a></p>

                        </div>
                    </div>
                </div>
            </section>

            <section class="ftco-section ftco-menu bg-light">
                <div class="container">
                    <div class="row justify-content-center mb-5 pb-3 wow fadeInUp">
                        <div class="col-md-7 heading-section text-center">
                            <span class="subheading" style={{color: '#2f89fc'}}> Restaurant </span>
                            <h2>Our Theme Dishes</h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-xl-6 d-flex wow slideInLeft" data-wow-delay="0.1s">
                            <div class="pricing-entry rounded d-flex">
                                <div class="img" style={{ backgroundImage: 'url(images/SNY02849.jpg)' }}></div>
                                <div class="desc p-4">
                                    <div class="d-md-flex text align-items-start">
                                    </div>
                                    <div class="d-block">
                                        <p>A delectable Sri Lankan seafood dish featuring golden-fried prawns, vibrant vegetables, and aromatic rice, beautifully garnished with a carved tomato rose.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-xl-6 d-flex wow slideInRight" data-wow-delay="0.2s">
                            <div class="pricing-entry rounded d-flex">
                                <div class="img" style={{ backgroundImage: 'url(images/SNY02854.jpg)' }} ></div>
                                <div class="desc p-4">
                                    <div class="d-md-flex text align-items-start">
                                    </div>
                                    <div class="d-block">
                                        <p>A flavorful Sri Lankan seafood curry served with steamed rice and a fresh side salad, offering a perfect blend of spice and tropical zest.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-xl-6 d-flex wow slideInLeft" data-wow-delay="0.3s">
                            <div class="pricing-entry rounded d-flex">
                                <div class="img" style={{ backgroundImage: 'url(images/SNY02877.jpg)' }}></div>
                                <div class="desc p-4">
                                    <div class="d-md-flex text align-items-start">
                                    </div>
                                    <div class="d-block">
                                        <p>Indulge in our exquisite seafood soup, brimming with vibrant flavors and garnished to perfection at The Jungle View.</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-xl-6 d-flex wow slideInRight" data-wow-delay="0.4s">
                            <div class="pricing-entry rounded d-flex">
                                <div class="img" style={{ backgroundImage: 'url(images/SNY02943.jpg)' }}></div>
                                <div class="desc p-4">
                                    <div class="d-md-flex text align-items-start">
                                    </div>
                                    <div class="d-block">
                                        <p>Enjoy Eat Catering Chicken Schnitzel, Potato Bake and seasonal vegetables with Traditional gravy.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-xl-6 d-flex wow slideInLeft" data-wow-delay="0.5s">
                            <div class="pricing-entry rounded d-flex">
                                <div class="img" style={{ backgroundImage: 'url(images/SNY02669.jpg)' }}></div>
                                <div class="desc p-4">
                                    <div class="d-md-flex text align-items-start">
                                    </div>
                                    <div class="d-block">
                                        <p>
                                            A classic Prawn Cocktail with a perfect Rose Marie Sauce for prawn cocktails. Easy, fast and a total crowd pleaser.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-xl-6 d-flex wow slideInRight" data-wow-delay="0.6s">
                            <div class="pricing-entry rounded d-flex">
                                <div class="img" style={{ backgroundImage: 'url(images/SNY02966.jpg)' }}></div>
                                <div class="desc p-4">
                                    <div class="d-md-flex text align-items-start">
                                    </div>
                                    <div class="d-block">
                                        <p>Enjoy the smoothly brewed delicacy of our completely natural fruit juices and beverages!</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Restaurant;