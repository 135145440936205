const Room = () => {
    return (
        <>
            <div class="hero-wrap" style={{ backgroundImage: 'url(images/banner.jpg)' }}>
                <div class="overlay"></div>
                <div class="container">
                    <div class="row no-gutters slider-text d-flex align-itemd-center justify-content-center">
                        <div class="col-md-9 text-center d-flex align-items-end justify-content-center">
                            <div class="text">
                                <p class="breadcrumbs mb-2"><span class="mr-2"><a href="index.html">Home</a></span> <span>Restaurant</span></p>
                                <h1 class="mb-4 bread">Rooms</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section class="ftco-section ftco-no-pb ftco-room">
                <div class="container-fluid px-0">
                    <div class="row no-gutters justify-content-center mb-5 pb-3">
                        <div class="col-md-7 heading-section text-center ">
                            <span class="subheading" style={{color: '#2f89fc'}}>Peacock Rooms</span>
                            <h2 class="mb-3">Our Rooms</h2>
                            <p style={{ paddingRight: '15px', paddingLeft: '15px' }}>Our rooms vary between different types where you can choose flexibly according to the number of pax and your purpose!</p>
                        </div>
                    </div>
                    <div class="row no-gutters">
                        <div class="col-lg-6">
                            <div class="room-wrap d-md-flex ">
                                <a href="#" class="img" style={{ backgroundImage: "url(images/gallery/image4.png)" }}></a>
                                <div class="half left-arrow d-flex align-items-center">
                                    <div class="text p-4 text-center">
                                        <p class="star mb-0"><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span></p>
                                        <p class="mb-0"><span class="price mr-1">$120.00</span> <span class="per">per night</span></p>
                                        <h3 class="mb-3"><a href="#">One-bedroom apartment</a></h3>
                                        <p class="mb-0"><span class="per">This apartment is comprised of 1 living room, 1 separate bedroom and 1 bathroom with a shower and free
                                            toiletries. This apartment is air-conditioned and features a seating area, a dining area and a wardrobe. The unit offers 2 beds.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="room-wrap d-md-flex ">
                                <a href="#" class="img" style={{ backgroundImage: "url(images/gallery/image5.png)" }}></a>
                                <div class="half left-arrow d-flex align-items-center">
                                    <div class="text p-4 text-center">
                                        <p class="star mb-0"><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span></p>
                                        <p class="mb-0"><span class="price mr-1">$120.00</span> <span class="per">per night</span></p>
                                        <h3 class="mb-3"><a href="#">Triple room with sea view</a></h3>
                                        <p class="mb-0"><span class="per">The triple room offers air conditioning, a seating area, a terrace with sea views
                                            as well as a private bathroom featuring a shower. The unit offers 2 beds.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="room-wrap d-md-flex ">
                                <a href="#" class="img order-md-last" style={{ backgroundImage: "url(images/gallery/image11.png)" }}></a>
                                <div class="half right-arrow d-flex align-items-center">
                                    <div class="text p-4 text-center">
                                        <p class="star mb-0"><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span></p>
                                        <p class="mb-0"><span class="price mr-1">$120.00</span> <span class="per">per night</span></p>
                                        <h3 class="mb-3"><a href="#">Family room with sea view</a></h3>
                                        <p class="mb-0"><span class="per">The family room provides air conditioning, a seating area, a terrace with
                                            sea views as well as a private bathroom featuring a shower. The unit offers 3 beds</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="room-wrap d-md-flex ">
                                <a href="#" class="img order-md-last" style={{ backgroundImage: "url(images/gallery/image12.png)" }}></a>
                                <div class="half right-arrow d-flex align-items-center">
                                    <div class="text p-4 text-center">
                                        <p class="star mb-0"><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span></p>
                                        <p class="mb-0"><span class="price mr-1">$120.00</span> <span class="per">per night</span></p>
                                        <h3 class="mb-3"><a href="#">Double room with sea view</a></h3>
                                        <p class="mb-0"><span class="per">Rooms are cooled with either a fan or air conditioning. They include
                                            a private balcony and en suite bathroom</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6">
                            <div class="room-wrap d-md-flex ">
                                <a href="#" class="img" style={{ backgroundImage: "url(images/gallery/image14.png)" }}></a>
                                <div class="half left-arrow d-flex align-items-center">
                                    <div class="text p-4 text-center">
                                        <p class="star mb-0"><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span></p>
                                        <p class="mb-0"><span class="price mr-1">$120.00</span> <span class="per">per night</span></p>
                                        <h3 class="mb-3"><a href="#">Deluxe double room with side sea view</a></h3>
                                        <p class="mb-0"><span class="per">The double room features air conditioning, a seating area, a terrace with sea views as well
                                            as a private bathroom boasting a shower. The unit has 1 bed</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="room-wrap d-md-flex ">
                                <a href="#" class="img" style={{ backgroundImage: "url(images/gallery/image20.png)" }}></a>
                                <div class="half left-arrow d-flex align-items-center">
                                    <div class="text p-4 text-center">
                                        <p class="star mb-0"><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span></p>
                                        <p class="mb-0"><span class="price mr-1">$120.00</span> <span class="per">per night</span></p>
                                        <h3 class="mb-3"><a href="#">Triple room with sea side view</a></h3>
                                        <p class="mb-0"><span class="per">The triple room offers air conditioning, a seating area, a terrace with sea
                                            views as well as a private bathroom boasting a shower. The unit has 2 beds.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="room-wrap d-md-flex ">
                                <a href="#" class="img order-md-last" style={{ backgroundImage: "url(images/gallery/image22.png)" }}></a>
                                <div class="half left-arrow d-flex align-items-center">
                                    <div class="text p-4 text-center">
                                        <p class="star mb-0"><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span></p>
                                        <p class="mb-0"><span class="price mr-1">$120.00</span> <span class="per">per night</span></p>
                                        <h3 class="mb-3"><a href="#">Single room with sea view</a></h3>
                                        <p class="mb-0"><span class="per">The single room provides air conditioning, a seating area, a terrace with sea
                                            views as well as a private bathroom featuring a shower.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="room-wrap d-md-flex ">
                                <a href="#" class="img order-md-last" style={{ backgroundImage: "url(images/gallery/image24.png)" }}></a>
                                <div class="half left-arrow d-flex align-items-center">
                                    <div class="text p-4 text-center">
                                        <p class="star mb-0"><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span></p>
                                        <p class="mb-0"><span class="price mr-1">$120.00</span> <span class="per">per night</span></p>
                                        <h3 class="mb-3"><a href="#">Standard triple room with sea view</a></h3>
                                        <p class="mb-0"><span class="per">The triple room offers air conditioning, a seating area,
                                            a terrace with sea views as well as a private bathroom featuring a shower. The unit offers 2 beds</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="room-wrap d-md-flex ">
                                <a href="#" class="img" style={{ backgroundImage: "url(images/gallery/image28.png)" }}></a>
                                <div class="half left-arrow d-flex align-items-center">
                                    <div class="text p-4 text-center">
                                        <p class="star mb-0"><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span><span class="ion-ios-star"></span></p>
                                        <p class="mb-0"><span class="price mr-1">$120.00</span> <span class="per">per night</span></p>
                                        <h3 class="mb-3"><a href="#">Apartment with Sea View</a></h3>
                                        <p class="mb-0"><span class="per">The apartment offers air conditioning, a seating area, a terrace with
                                            sea views as well as a private bathroom featuring a shower. The unit has 3 beds.</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Room;
