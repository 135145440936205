// src/Components/Loading.js
import React from 'react';
import WOW from 'wowjs';

const Loading = () => {
  // Initialize Wow.js
  React.useEffect(() => {
    new WOW.WOW().init();
  }, []);

  return (
    <div className="loading-screen">
      <img src='logo1.png' alt="Logo" className="loading-logo" />
    </div>
  );
};

export default Loading;
