import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Home } from './Pages/Home';
import Navbar from './Components/Navbar';
import Footer from './Components/Footer';
import Contact from './Pages/Contact';
import About from './Pages/About';
import Blog from './Pages/Blog';
import Room from './Pages/Room';
import Restaurant from './Pages/Restaurant';
import Loading from './Components/Loading';

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    // Simulate loading process
    const timer = setTimeout(() => {
      setFadeOut(true); // Trigger the fade-out effect
      setTimeout(() => setIsLoading(false), 800); // Hide the loading screen after the fade-out completes
    }, 1000); // Adjust the initial timeout duration as needed

    return () => clearTimeout(timer); // Clean up timer on component unmount
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        {isLoading && <Loading fadeOut={fadeOut} />} {/* Pass fadeOut to Loading */}
        {!isLoading && (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/about" element={<About />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/room" element={<Room />} />
            <Route path="/restaurant" element={<Restaurant />} />
          </Routes>
        )}
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
