import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import emailjs from 'emailjs-com';
import 'animate.css';
import WOW from 'wowjs';

const Contact = () => {

    const location = useLocation();

    const bookingDetails = location.state || {}; // Get the passed state

    const { checkIn, checkOut, adults, room } = bookingDetails;

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        startDate: checkIn,
        endDate: checkOut,
        adults: adults,
        room: room,
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.send(
            'service_kp7b6',       // Replace with your EmailJS service ID
            'template_smkhf',      // Replace with your EmailJS template ID
            formData,
            'TbZcVEvka6Gbk0m'      // Replace with your EmailJS user ID
        )
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                alert('Message sent successfully!');
            })
            .catch((err) => {
                console.error('FAILED...', err);
                alert('Failed to send message. Please try again later.');
            });

        setFormData({
            name: '',
            email: '',
            phone: '',
            startDate: checkIn,
            endDate: checkOut,
            adults: adults,
            room: room,
            message: '',
        });
    };

    // Initialize Wow.js
    React.useEffect(() => {
        new WOW.WOW().init();
    }, []);

    return (
        <>
            <div class="hero-wrap" style={{ backgroundImage: 'url(images/banner.jpg)' }}>
                <div class="overlay"></div>
                <div class="container">
                    <div class="row no-gutters slider-text d-flex align-itemd-center justify-content-center">
                        <div class="col-md-9 text-center d-flex align-items-end justify-content-center">
                            <div class="text">
                                <p class="breadcrumbs mb-2"><span class="mr-2"><a href="/">Home</a></span> <span>Contact Us</span></p>
                                <h1 class="mb-4 bread">Contact Us</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section class="ftco-section contact-section bg-light">
                <div class="container">
                    <div class="row d-flex mb-5 contact-info">
                        <div class="col-md-12 mb-4 wow fadeInDown">
                            <h2 class="h3">Contact Information</h2>
                        </div>
                        <div class="w-100"></div>
                        <div class="col-md-3 d-flex wow fadeInUp">
                            <div class="info rounded bg-white p-4">
                                <h4>Address:</h4> <p>Peacock hotel, <br />Matara  Road,<br />Peellagoda,<br />Unawatuna.</p>
                            </div>
                        </div>
                        <div class="col-md-3 d-flex wow fadeInUp">
                            <div class="info rounded bg-white p-4">
                                <h4>Phone:</h4>
                                <p>(+94) 77 648 0155</p>
                            </div>
                        </div>
                        <div class="col-md-3 d-flex wow fadeInUp">
                            <div class="info rounded bg-white p-4">
                                <h4>Email:</h4>
                                <p>peacockhotelunawatuna1<br />@gmail.com</p>
                            </div>
                        </div>
                        <div class="col-md-3 d-flex wow fadeInUp">
                            <div class="info rounded bg-white p-4">
                                <h4>Reception Hours</h4>
                                <p>7.00 AM - 11.00 PM</p>
                            </div>
                        </div>
                    </div>
                    <div class="row block-9">
                        <div class="col-md-6 order-md-last d-flex wow slideInRight">
                            <form class="bg-white p-5 contact-form" onSubmit={handleSubmit} >
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div class="form-group">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="name"
                                            placeholder="Your Name"
                                            name="name"
                                            value={formData.name}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div class="form-group">
                                        <input
                                            type="email"
                                            className="form-control"
                                            id="email"
                                            placeholder="Your Email"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div class="form-group">
                                        <input
                                            type="startDate"
                                            className="form-control"
                                            id="startDate"
                                            placeholder="Checkout Date"
                                            name="startDate"
                                            value={formData.startDate}
                                            onChange={handleChange}
                                            // disabled
                                        />
                                    </div>
                                    <div class="form-group">
                                        <input
                                            type="endDate"
                                            className="form-control"
                                            id="endDate"
                                            placeholder="Checkout Date"
                                            name="endDate"
                                            value={formData.endDate}
                                            onChange={handleChange}
                                            // disabled
                                        />
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div class="form-group">
                                        <input
                                            type="phone"
                                            className="form-control"
                                            id="phone"
                                            placeholder="Your Phone"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div class="form-group">
                                        <input
                                            type="adults"
                                            className="form-control"
                                            id="adults"
                                            placeholder="Adults"
                                            name="adults"
                                            value={formData.adults}
                                            onChange={handleChange}
                                            // disabled
                                        />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <input
                                        type="room"
                                        className="form-control"
                                        id="room"
                                        placeholder="Room"
                                        name="room"
                                        value={formData.room}
                                        onChange={handleChange}
                                        // disabled
                                    />
                                </div>
                                <div class="form-group">
                                    <textarea name="message" id="message" cols="30" rows="7" class="form-control" placeholder="Message" value={formData.message} onChange={handleChange}></textarea>
                                </div>
                                <div class="form-group" style={{ color: '#2f89fc' }}>
                                    <input style={{ backgroundColor: '#2f89fc' }} type="submit" value="Send Message" class="btn btn-primary py-3 px-5" />
                                </div>
                            </form>

                        </div>

                        <div class="col-md-6 d-flex wow slideInLeft">
                            <div id="map" class="bg-white">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3967.8915118018945!2d80.25008059999999!3d6.009632!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ae172ec353f357b%3A0x960641550deddb44!2sPeacock%20Hotel!5e0!3m2!1sen!2slk!4v1721282594765!5m2!1sen!2slk"
                                    height="100%" width="100%" style={{ border: 0 }} allowFullScreen=""></iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Contact;